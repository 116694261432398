import React from 'react'
import {Container, Row, Col} from 'reactstrap'
import RecentPostArea from '../recet-post-area'
import Siderbar from '../../global/sidebar'
import {MainWrapper} from './main-area.stc'

const MainArea = ({allBlogs, categoriesSummary, recentPosts}) => {

    return (
        <MainWrapper>
            <Container>
                <Row>
                    <Col lg={8}>
                        <RecentPostArea allBlogs={allBlogs}/>
                    </Col>
                    <Col lg={4}>
                    <Siderbar categoriesSummary={categoriesSummary} recentPosts={recentPosts}/>
                    </Col>
                </Row>
            </Container>
        </MainWrapper>
    )
}

MainArea.defaultProps = {
    allBlogs: [ ],
    categoriesSummary: {},
    recentPosts: [],
}

export default MainArea
