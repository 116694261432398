import React from "react"
import { graphql } from 'gatsby'
import get from 'lodash/get'
import { getCategoriesSummaryFromPosts, getRecentPostsFromEdges, getFeaturePostsFromEdges} from '../utils/utilFunctions'
import SEO from '../components/seo'
import Layout from "../containers/layout/layout"
import Header from '../containers/layout/header'
import Footer from '../containers/layout/footer'
import HeroArea from '../containers/home/hero-area'
import MainArea from '../containers/home/main-area'

class IndexPage extends React.Component {
  render() {
    const [author] = get(this, 'props.data.allContentfulPerson.edges')
    const filesystemPosts = []

    let posts = get(this, 'props.data.allContentfulBlogPost.edges').map( ({ node }) => {
      const {text: quote_text, author: quote_author} = node.quote !== null ? node.quote : { };

      return {
          node: {
            fields: {
                slug: node.slug,
                collection: 'contentfulPosts',
                dateSlug: node.dateSlug,
            },
            frontmatter: {
                sortKey: node.sortKey,
                date: node.date,
                format: node.format,
                title: node.title,
                video_link: node.video_link,
                quote_text: quote_text,
                quote_author: quote_author,
                link: node.link,
                image: node.image,
                images: node.images,
                category: node.category,
                is_featured: node.is_featured,
                recentImage: node.recentImage,
            },
            excerpt: node.body.childMarkdownRemark.excerpt,
        }
      }
    });

  const categoriesSummary = getCategoriesSummaryFromPosts(posts);
  const featurePosts = getFeaturePostsFromEdges(get(this, 'props.data.featureContentfulBlogPost.edges'));

  const recentFilesystemPosts = []
  const recentContentfulPosts = get(this, 'props.data.recentContentfulBlogPost.edges')
  const recentPosts = getRecentPostsFromEdges(recentFilesystemPosts, recentContentfulPosts);

  return (
      <Layout>
      <SEO title="Home" />
      <Header authorData={author.node}/>
      <div className="main-content">
        <HeroArea featurePosts={featurePosts}/>
        <MainArea allBlogs={posts} categoriesSummary={categoriesSummary} recentPosts={recentPosts}/>  
      </div>
      <Footer/>
    </Layout>
    )
  }
}

export default IndexPage

export const pageQuery = graphql`
  query {
    featureContentfulBlogPost: allContentfulBlogPost(
        filter: { is_featured : { eq: true } }
        sort: {order: DESC, fields: publishDate}
        limit: 4
    ) {
        edges {
            node {
            ...MajorContentfulBlogPostField
            body {
                childMarkdownRemark {
                    excerpt(format: PLAIN, pruneLength: 85)
                }
            }
            is_featured
            }
        }
    }    
    recentContentfulBlogPost: allContentfulBlogPost(
      filter: { format : { eq: "image" } }
      sort: {order: DESC, fields: publishDate}
      limit: 4
    ) {
        edges {
            node {
              ...RecentContentfulBlogPostField
            }
        }
    }
    allContentfulBlogPost(
      sort: {order: DESC, fields: publishDate}
    ) {
        edges {
            node {
              ...MajorContentfulBlogPostField
              body {
                  childMarkdownRemark {
                      excerpt(format: PLAIN, pruneLength: 85)
                  }
              }
              is_featured
            }
        }
    }
    allContentfulPerson(filter: { id: { eq: "801d0181-aa93-5da9-82f1-fae3b71554af" } }) {
      edges {
        node {
          ...ContentfulPersonField
        }
      }
    }
  }
`